import React, {useState, useEffect } from "react";
import AboutPageContent from "../pagecontent/AboutPageContent";
import "../css/aboutPage.css";
import "../css/animations.css";
import "../css/characteristicsCard.css";
import MyImage from "../images/selfimage.JPG";
// import PDF from '../files/Waathiq Juma Resume.pdf';
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

const AboutHeader = () => {
  return (
    <div className="headingSpace">
      <h1 className="headingFontStyle">{AboutPageContent.headingText}</h1>
    </div>
  );
};

const Icons = () => {
  return (
    <div className="flex-container">
      <div className="bullet-wrap">
        <motion.i whileHover={{scale: 1.1, backgroundColor: "#616161"}} className="bi bi-speedometer2 centerIcons" id="hexagon"></motion.i>
        <span className="spanFont">{AboutPageContent.icontTextOneHeading}</span>
        <p id="paragraphFont">{AboutPageContent.icontTextOnePar}</p>
      </div>

      <div className="bullet-wrap">
        <motion.i whileHover={{scale: 1.1, backgroundColor: "#616161"}} className="bi bi-laptop centerIcons" id="hexagon"></motion.i>
        <span className="spanFont">{AboutPageContent.icontTextTwoHeading}</span>
        <p id="paragraphFont">{AboutPageContent.icontTextTwoPar}</p>
      </div>

      <div className="bullet-wrap">
        <motion.i whileHover={{scale: 1.1, backgroundColor: "#616161"}} className="bi bi-lightbulb centerIcons" id="hexagon"></motion.i>
        <span className="spanFont">
          {AboutPageContent.icontTextThreeHeading}
        </span>
        <p id="paragraphFont">{AboutPageContent.icontTextThreePar}</p>
      </div>

      <div className="bullet-wrap">
        <motion.i whileHover={{scale: 1.1, backgroundColor: "#616161"}} className="bi bi-speedometer2 centerIcons" id="hexagon"></motion.i>
        <span className="spanFont">
          {AboutPageContent.icontTextFourHeading}
        </span>
        <p id="paragraphFont">{AboutPageContent.icontTextFourPar}</p>
      </div>
    </div>
  );
};

const DisplayButtonCards = () => {
  const [showcard, setShowCard] = useState(false);
  const [showcardOne, setShowCardOne] = useState(false);
  const [showcardTwo, setShowCardTwo] = useState(false);
  const [showcardThree, setShowCardThree] = useState(false);

  const charCardInfor = [
    {
      id: 0,
      content: AboutPageContent.charCardContent,
    },
    {
      id: 1,
      content: AboutPageContent.charCardOneContent,
    },
    {
      id: 2,
      content: AboutPageContent.charCardTwoContent,
    },
    {
      id: 3,
      content: AboutPageContent.charCardThreeContent,
    },
  ];

  const onClickHandlerCardDisplay = ()=>{
    const cardIndexValue = charCardInfor[0].id;
    
    if (cardIndexValue === 0) {
      setShowCard(true);
    } else {
      setShowCard(false);
    }
  }

  const onClickHandlerCardOneDisplay = ()=>{
    const cardIndexValue = charCardInfor[1].id;
    
    if (cardIndexValue === 1) {
      setShowCardOne(true);
    } else {
      setShowCardOne(false);
    }
  }

  const onClickHandlerCardTwoDisplay = ()=>{
    const cardIndexValue = charCardInfor[2].id;
    
    if (cardIndexValue === 2) {
      setShowCardTwo(true);
    } else {
      setShowCardTwo(false);
    }
  }

  const onClickHandlerCardThreeDisplay = ()=>{
    const cardIndexValue = charCardInfor[3].id;
    
    if (cardIndexValue === 3) {
      setShowCardThree(true);
    } else {
      setShowCardThree(false);
    }
  }

  const Card = () => {
    return (
      <div className="cardOne">
        <div className="card-containerOne">
          <div className="card-frontOne">
            <img src={AboutPageContent.teamworkImage} alt="Teamwork"></img>
          </div>
          <div className="card-backOne">
            <p id="fontSize">{charCardInfor[0].content}</p>
          </div>
        </div>
      </div>
    );
  };

  const CardOne = () => {
    return (
      <div className="cardOne">
        <div className="card-containerOne">
          <div className="card-frontOne">
            <img src={AboutPageContent.ambitousImage} alt="Ambitous"></img>
          </div>
          <div className="card-backOne">
            <p id="fontSize">{charCardInfor[1].content}</p>
          </div>
        </div>
      </div>
    );
  };

  const CardTwo = () => {
    return (
      <div className="cardOne">
        <div className="card-containerOne">
          <div className="card-frontOne">
            <img src={AboutPageContent.learningImage} alt="Learning"></img>
          </div>
          <div className="card-backOne">
            <p id="fontSize">{charCardInfor[2].content}</p>
          </div>
        </div>
      </div>
    );
  };

  const CardThree = () => {
    return (
      <div className="cardOne">
        <div className="card-containerOne">
          <div className="card-frontOne">
            <img src={AboutPageContent.workethicImage} alt="Work Ethic"></img>
          </div>
          <div className="card-backOne">
            <p id="fontSize">{charCardInfor[3].content}</p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="flex-container flipCards">
      <div className="bullet-wrap">
        <motion.button
        whileHover={{scale: 1.1 }}
        whileTap={{scale: 0.9}}
        type="button" className="btn btn-primary characterButton" onClick={onClickHandlerCardDisplay}>
          Teamwork
        </motion.button>
        {showcard ? <Card/> : null}
      </div>

      <div className="bullet-wrap">
        <motion.button
        whileHover={{scale: 1.1 }}
        whileTap={{scale: 0.9}} 
        type="button" className="btn btn-primary characterButton" onClick={onClickHandlerCardOneDisplay}>
          Ambitious
        </motion.button>
        {showcardOne ? <CardOne/> : null}
      </div>

      <div className="bullet-wrap">
        <motion.button 
        whileHover={{scale: 1.1 }}
        whileTap={{scale: 0.9}}
        type="button" className="btn btn-primary characterButton" onClick={onClickHandlerCardTwoDisplay}>
          Learning
        </motion.button>
        {showcardTwo ? <CardTwo/> : null}
      </div>

      <div className="bullet-wrap">
        <motion.button 
        whileHover={{scale: 1.1 }}
        whileTap={{scale: 0.9}}
        type="button" className="btn btn-primary characterButton" onClick={onClickHandlerCardThreeDisplay}>
          Work Ethic
        </motion.button>
        {showcardThree ? <CardThree/> : null}

      </div>
    </div>
  );
};

const WhoAmI = () => {

  const controls = useAnimation();
  const { ref, inView } = useInView();
  

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  const animatedVariantsLeft = {
    hidden: { opacity: 0, x: -1000 },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 1.0,
        ease: "easeIn",
      },
    },
  };

  const animatedVariantsRight = {
    hidden: { opacity: 0, x: 500 },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 1.0,
        ease: "easeIn",
      },
    },
  };

  const progressBarAnimationOne = {
    hidden: { opacity: 0, x: 500 },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 1.0,
        delay: 1,
        type: "spring", 
        stiffness: 120,
      },
    },
  };

  const progressBarAnimationTwo = {
    hidden: { opacity: 0, x: 500 },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 1.0,
        delay: 1,
        type: "spring", 
        stiffness: 150,
      },
    },
  };

  const progressBarAnimationThree = {
    hidden: { opacity: 0, x: 500 },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 1.0,
        delay: 1,
        type: "spring", 
        stiffness: 170,
      },
    },
  };

  const progressBarAnimationFour = {
    hidden: { opacity: 0, x: 500 },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 1.0,
        delay: 1,
        type: "spring", 
        stiffness: 190,
      },
    },
  };

  const progressBarAnimationFive = {
    hidden: { opacity: 0, x: 500 },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 1.0,
        delay: 1,
        type: "spring", 
        stiffness: 210,
      },
    },
  };

  return (
    <motion.div className="flex-container imageContainer">
      <motion.div className="flex-section-left"
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={animatedVariantsLeft}
      >
        <img className="imagePosition" src={MyImage} alt="architect"></img>
        <div className="bullet-wrap">
          <span className="spanFont">{AboutPageContent.imageHeadingText}</span>
          <p id="paragraphFont">{AboutPageContent.imageTextPar}</p>
        </div>
      </motion.div>

      <motion.div className="flex-section-right"
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={animatedVariantsRight}

          
      >
        <h3 className="listHeading">{AboutPageContent.listHeadingText}</h3>
        <ul>
          <motion.div
            ref={ref}
            initial="hidden"
            animate={controls}
            variants={progressBarAnimationOne}
            className="progress">
            <div className="textListBgColor">
              {AboutPageContent.listTextOne}
            </div>
            <div
              className="progress-bar"
              id="java"
              role="progressbar"
              aria-valuenow="0"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
            
          </motion.div>
        </ul>

        <ul>
          <motion.div
           ref={ref}
           initial="hidden"
           animate={controls}
           variants={progressBarAnimationTwo}
           className="progress">
            <div className="textListBgColor">
              {AboutPageContent.listTextSeven}
            </div>
            <div
              className="progress-bar"
              id="selenium"
              role="progressbar"
              aria-valuenow="0"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
            
          </motion.div>
        </ul>

        <ul>
          <motion.div 
          ref={ref}
          initial="hidden"
          animate={controls}
          variants={progressBarAnimationTwo}
          className="progress">
            <div className="textListBgColor">
              {AboutPageContent.listTextTen}
            </div>
            <div
              className="progress-bar"
              id="testcafe"
              role="progressbar"
              aria-valuenow="0"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
            
          </motion.div>
        </ul>

        <ul>
          <motion.div 
          ref={ref}
          initial="hidden"
          animate={controls}
          variants={progressBarAnimationThree}
          className="progress">
            <div className="textListBgColor">
              {AboutPageContent.listTextTwo}
            </div>
            <div
              className="progress-bar"
              id="react"
              role="progressbar"
              aria-valuenow="0"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
            
          </motion.div>
        </ul>

        <ul>
          <motion.div 
          ref={ref}
          initial="hidden"
          animate={controls}
          variants={progressBarAnimationThree}
          className="progress">
            <div className="textListBgColor">
              {AboutPageContent.listTextFour}
            </div>
            <div
              className="progress-bar"
              id="html"
              role="progressbar"
              aria-valuenow="0"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
            
          </motion.div>
        </ul>

        <ul>
          <motion.div 
          ref={ref}
          initial="hidden"
          animate={controls}
          variants={progressBarAnimationThree}
          className="progress">
            <div className="textListBgColor">
              {AboutPageContent.listTextFive}
            </div>
            <div
              className="progress-bar"
              id="css"
              role="progressbar"
              aria-valuenow="0"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
            
          </motion.div>
        </ul>

        <ul>
          <motion.div 
          ref={ref}
          initial="hidden"
          animate={controls}
          variants={progressBarAnimationFour}
          className="progress">
            <div className="textListBgColor">
              {AboutPageContent.listTextEleven}
            </div>
            <div
              className="progress-bar"
              id="gitlab"
              role="progressbar"
              aria-valuenow="0"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
            
          </motion.div>
        </ul>

        <ul>
          <motion.div 
          ref={ref}
          initial="hidden"
          animate={controls}
          variants={progressBarAnimationFive}
          className="progress">
            <div className="textListBgColor">
              {AboutPageContent.listTextTwelve}
            </div>
            <div
              className="progress-bar"
              id="jira"
              role="progressbar"
              aria-valuenow="0"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
            
          </motion.div>
        </ul>

        <ul>
          <motion.div 
          ref={ref}
          initial="hidden"
          animate={controls}
          variants={progressBarAnimationFive}
          className="progress">
            <div className="textListBgColor">
              {AboutPageContent.listTextSix}
            </div>
            <div
              className="progress-bar"
              id="dedication"
              role="progressbar"
              aria-valuenow="0"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
            
          </motion.div>
        </ul>

        {/* <motion.a href={PDF} download="WaathiqJumaResume" target='_blank' className="btn btn-primary" id="downloadBtn"
                    ref={ref}
                    initial="hidden"
                    animate={controls}
                    variants={progressBarAnimationFive}
                    whileHover={{scale: 1.1, backgroundColor: "#616161"}}>
              <i className="bi bi-download" id="downloadIcon">Download Resume</i>
              
        </motion.a> */}
        {/* <p id="resumeParagraph">Hi, I have acquired over five years of experience as a Lead Test Automation Engineer in the IT industry. My expertise is in Quality Assurance, with a solid understanding of the Sofware Development Life Cycle (SDLC) and object-oriented methodologies. Excellent experience in Test Automation in Web-based applications using both Automated and Manual techniques with open-source tools. If you want to know more about me and my work history, tools, and skillsets, click on the above DOWNLOAD RESUME button and deep dive into my resume.</p> */}
      </motion.div>
    </motion.div>
  );
};

const FadeInWhenVisible = () => {
  const controls = useAnimation();
  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  const animatedVariants = {
    hidden: { scale: 0 },
    visible: {
      scale: 1,
      transition: {
        duration: 1.0,
      },
    },
  };

  return (
    <motion.div
      ref={ref}
      className="AboutFadeIn"
      initial="hidden"
      animate={controls}
      variants={animatedVariants}
    >
      <AboutHeader />
      <Icons />
      <WhoAmI />
      <DisplayButtonCards />
    </motion.div>
  );
};

class About extends React.Component {
  render() {
    return (
      <section id="about">
        <FadeInWhenVisible />
      </section>
    );
  }
}

export default About;
