import CardArtPageObjectModal from '../images/pageObjectModal.jpg';
import CardArtReact from '../images/reactjs.jpg';
import CardArtJava from '../images/java.jpg';
import CardArtJavaScript from '../images/javascript.jpg';
import CardArtAccomplishments from '../images/accomplishments.jpg';
import CardArtOnlineLearning from '../images/onlinelearning.jpg';
import CardArtWorkHard from '../images/workhard.jpg';
import CardArtGoals from '../images/goals.jpg';
import MultipleTools from '../images/multipletools.jpg';
import BitslogicalAbout from '../images/bitslogicalAbout.JPG';
import BitslogicalContactUs from '../images/bitslogicalContactUs.JPG';
import BitslogicalVideo from '../images/bitslogicalVideo.JPG';
import ParadigmHome from '../images/paradigmHome.JPG';
import ParadigmAbout from '../images/paradigmAbout.JPG';
import ParadigmContact from '../images/paradigmContact.JPG';

const PortfolioCardsContent = {
    cardArtImagePageObecjtModal:CardArtPageObjectModal,
    cardArtImageReact:CardArtReact,
    cardArtImageJava:CardArtJava,
    cardArtImageJavaScript:CardArtJavaScript,
    cardArtImageAccomplishments:CardArtAccomplishments,
    cardArtImageOnlineLearning:CardArtOnlineLearning,
    cardArtImageWorkHard:CardArtWorkHard,
    cardArtImageGoals:CardArtGoals,
    cardArtImageMultipleTools:MultipleTools,
    cardArtImageBitslogicalAbout:BitslogicalAbout,
    cardArtImageBitslogicalContactUs:BitslogicalContactUs,
    cardArtImageBitslogicalVideo:BitslogicalVideo,
    cardArtImageParadigmHome:ParadigmHome,
    cardArtImageParadigmAbout:ParadigmAbout,
    cardArtImageParadigmContact:ParadigmContact,
    headingTextOne:"BitsLogical",
    paragraphTextOne:"React",
    headingTextTwo:"Paradigm",
    paragraphTextTwo:"React",
    headingTextThree:"Automation Projects",
    paragraphTextThree:"Automation",
    headingTextFour:"TestCafe",
    paragraphTextFour:"Automation",
    headingTextFive:"Accomplishments",
    paragraphTextFive:"Certificates",
     
}

export default PortfolioCardsContent;