import React from "react";
import BackdropModal from "../components/BackdropModal";
import PortfolioCardsPageContent from "../pagecontent/PortfolioCardsPageContent";
import BackdropModalPageContent from "../pagecontent/BackdropModalPageContent";
import "../css/portfolioCards.css";
import "../css/buttonGroup.css";

const OriginalCardArt = (props) => {
  return (
    <div className="card" id={props.idNum}>
      <img src={props.image} className="card-img-top" alt="..."></img>
      <div className="card-body">
        <h5 className="card-title">
          {props.headings}
          <p className="card-text">{props.paragraphs}</p>
        </h5>
        <BackdropModal modalNames={props.modalInfo} />
      </div>
    </div>
  );
};

const ListOfCardArt = () => {
  const cardInfoList = [
    {
      image: PortfolioCardsPageContent.cardArtImageReact,
      heading: PortfolioCardsPageContent.headingTextOne,
      paragraph: PortfolioCardsPageContent.paragraphTextOne,
      id: "React",
      indexValue: 1,
      modalInformation: {
        headingFive: BackdropModalPageContent.headingFiveTextOne,
        bodyParagraph: BackdropModalPageContent.bodyParagraphOne,
        secondButton: BackdropModalPageContent.secondButton,
        thirdButton: BackdropModalPageContent.thirdButton,
        indexValue: 1,
        buttonLink: BackdropModalPageContent.buttonLinkOne,
        carImgs: [
          {
            img: PortfolioCardsPageContent.cardArtImageBitslogicalVideo,
            carID:1
          },
          {
            img: PortfolioCardsPageContent.cardArtImageBitslogicalContactUs,
            carID:2
          },
          {
            img: PortfolioCardsPageContent.cardArtImageBitslogicalAbout,
            carID:3
          },
        ],
      },
    },
    {
      image: PortfolioCardsPageContent.cardArtImageReact,
      heading: PortfolioCardsPageContent.headingTextTwo,
      paragraph: PortfolioCardsPageContent.paragraphTextTwo,
      id: "React",
      indexValue: 2,
      modalInformation: {
        headingFive: BackdropModalPageContent.headingFiveTextTwo,
        bodyParagraph: BackdropModalPageContent.bodyParagraphTwo,
        secondButton: BackdropModalPageContent.secondButton,
        thirdButton: BackdropModalPageContent.thirdButton,
        indexValue: 2,
        buttonLink: BackdropModalPageContent.buttonLinkTwo,
        carImgs: [
          {
            img: PortfolioCardsPageContent.cardArtImageParadigmHome,
            carID:1
          },
          {
            img: PortfolioCardsPageContent.cardArtImageParadigmAbout,
            carID:2
          },
          {
            img: PortfolioCardsPageContent.cardArtImageParadigmContact,
            carID:3
          },
        ],
      },

    },
    {
      image: PortfolioCardsPageContent.cardArtImageMultipleTools,
      heading: PortfolioCardsPageContent.headingTextThree,
      paragraph: PortfolioCardsPageContent.paragraphTextThree,
      id: "Automation",
      indexValue: 3,
      modalInformation: {
        headingFive: BackdropModalPageContent.headingFiveTextThree,
        bodyParagraph: BackdropModalPageContent.bodyParagraphThree,
        secondButton: BackdropModalPageContent.secondButton,
        thirdButton: BackdropModalPageContent.thirdButtonAutomation,
        indexValue: 3,
        buttonLink: BackdropModalPageContent.buttonLinkThree,
        carImgs: [
          {
            img: PortfolioCardsPageContent.cardArtImageJava,
            carID:1
          },
          {
            img: PortfolioCardsPageContent.cardArtImageJavaScript,
            carID:2
          },
          {
            img: PortfolioCardsPageContent.cardArtImagePageObecjtModal,
            carID:3
          },
        ],
      },
    },
    // {
    //   image: PortfolioCardsPageContent.cardArtImageJavaScript,
    //   heading: PortfolioCardsPageContent.headingTextFour,
    //   paragraph: PortfolioCardsPageContent.paragraphTextFour,
    //   id: "Automation",
    //   indexValue: 4,
    //   modalInformation: {
    //     headingFive: BackdropModalPageContent.headingFiveTextFour,
    //     bodyParagraph: BackdropModalPageContent.bodyParagraphFour,
    //     secondButton: BackdropModalPageContent.secondButton,
    //     thirdButton: BackdropModalPageContent.thirdButtonAutomation,
    //     indexValue: 4,
    //     buttonLink: BackdropModalPageContent.buttonLinkFour,
    //     carImgs: [
    //       {
    //         img: PortfolioCardsPageContent.cardArtImageTestCafe,
    //         carID:1
    //       },
    //       {
    //         img: PortfolioCardsPageContent.cardArtImageBrowserStack,
    //         carID:2
    //       },
    //       {
    //         img: PortfolioCardsPageContent.cardArtImageReportPortal,
    //         carID:3
    //       },
    //     ],
    //   },
    // },
    {
      image: PortfolioCardsPageContent.cardArtImageAccomplishments,
      heading: PortfolioCardsPageContent.headingTextFive,
      paragraph: PortfolioCardsPageContent.paragraphTextFive,
      id: "Certificates",
      indexValue: 5,
      modalInformation: {
        headingFive: BackdropModalPageContent.headingFiveTextFive,
        bodyParagraph: BackdropModalPageContent.bodyParagraphFive,
        secondButton: BackdropModalPageContent.secondButton,
        thirdButton: BackdropModalPageContent.thirdButtonCertifcates,
        indexValue: 5,
        buttonLink: BackdropModalPageContent.buttonLinkFive,
        carImgs: [
          {
            img: PortfolioCardsPageContent.cardArtImageOnlineLearning,
            carID:1
          },
          {
            img: PortfolioCardsPageContent.cardArtImageWorkHard,
            carID:2
          },
          {
            img: PortfolioCardsPageContent.cardArtImageGoals,
            carID:3
          },
        ],
      },
    },
  ];


  //CardArt Handler Code Work needs to be done for filtiering
  // const [displayCard, setdisplayCard] = useState('');
  
  // const cardArtHandler = () => {
  //   const reactCards = cardInfoList.filter((cardInfo) => {
  //     if (cardInfo.id === "React") {
  //       setdisplayCard("card")
  //     return cardInfo.id === "React";
  //     }else{
  //       setdisplayCard("noDisplay")
  //     }
      
  //   });
  //   console.log(reactCards);
  // }

  return (
    <div>
      {/* <div className="btn-group" role="group" aria-label="Basic outlined example">
        <button type="button" className="btn btn-outline-primary" id="buttonGroup" value="All">
          {PortfolioPageContent.buttonGroupTextOne}
        </button>
        
        <button type="button" className="btn btn-outline-primary" id="buttonGroup" onClick={cardArtHandler}>
          {PortfolioPageContent.buttonGroupTextTwo}
        </button>

        <button type="button" className="btn btn-outline-primary" id="buttonGroup">
          {PortfolioPageContent.buttonGroupTextThree}
        </button>

        <button type="button" className="btn btn-outline-primary" id="buttonGroup">
          {PortfolioPageContent.buttonGroupTextFour}
        </button>
      </div> */}

      {cardInfoList.map((cardInfo) => (
        <OriginalCardArt
          key={cardInfo.indexValue}
          idNum={cardInfo.id}
          image={cardInfo.image}
          headings={cardInfo.heading}
          paragraphs={cardInfo.paragraph}
          modalInfo={cardInfo.modalInformation}
        />
      ))}
    </div>
  );
};

class PortfolioCards extends React.Component {
  render() {
    return (
      <div className="flex-container cardsContainer">
        <ListOfCardArt />
      </div>
    );
  }
}

export default PortfolioCards;
