const BackdropModalPageContent = {
  headingFiveTextOne: "Bitslogical Project",
  headingFiveTextTwo: "Paradigm Project",
  headingFiveTextThree: "Automation Projects",
  headingFiveTextFour: "TestCafe",
  headingFiveTextFive: "Certificates",
  bodyParagraphOne: "Bitslogical is a company that provides businesses and consumers with network and security infrastructure services.",
  bodyParagraphTwo: "Paradigm Equity Partners is a private-based equity firm that seeks to acquire and operate in a middle market. Its primary experience extends into various industries, including Aerospace, Automotive, Chemicals, Food, Metals & Technology.",
  bodyParagraphThree: "Here are a couple of frameworks and automation projects I currently work with at U.S. Bank (Selenium, TestCafe, & Rest Assured). The framework's languages and libraries are Gherkin & Cucumber, Java, and Javascript.",
  bodyParagraphFour: "TBD",
  bodyParagraphFive: "Here are the achievements that I have obtained by seeking out knowledge through various courses and training programs.",
  secondButton: "Close",
  thirdButton: "View Site",
  thirdButtonAutomation: "View Source Code",
  thirdButtonCertifcates: "View Certificates",
  buttonLinkOne:"https://www.bitslogiproject.com",
  buttonLinkTwo:"https://www.paradigmequitypartners.com",
  buttonLinkThree:"https://gitlab.com/automation-projects2",
  buttonLinkFour:"TBDLink4",
  buttonLinkFive:"https://drive.google.com/drive/folders/12dkyDz1ASv7AvbN6V94BoXVrd8RjCCu9?usp=sharing",
  hiddenButtonOne:"https://gitlab.com/react-projects20",
 

};

export default BackdropModalPageContent;
