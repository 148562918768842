import React from "react";
import { Link } from "react-scroll";
import { motion } from "framer-motion";
import "../css/footerPrimaryButton.css";

const FooterPrimaryButton = () => {
  return (
    <motion.button whileHover={{scale: 1.1, boxShadow: "0px 0px 8px rgb(255,255,255)"}} type="button" className="btn btn-outline-primary-ftr" id="footerButton">
      <Link to="home" spy={true} smooth={true}>      
      <motion.i whileHover={{color: "#616161"}} className="bi bi-chevron-double-up" id="arrowIconUp"></motion.i>
      </Link>
    </motion.button>
  );
};

export default FooterPrimaryButton;
