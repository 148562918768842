import React from "react";
import { motion } from "framer-motion";
import FooterButton from "../components/FooterPrimaryButton";
import "../css/footer.css";

const Footer = () => {
  
  return (

<div className="footer">
<FooterButton/>
      <div className="example-container">
        <section className="listOfIcons">
        
          <motion.a whileHover={{textShadow: "0px 0px 5px rgb(255,255,255)", boxShadow: "0px 0px 10px rgb(255,255,255)"}} href="https://www.linkedin.com/in/waathiq-juma" target="_blank" role="button">
            <motion.div whileHover={{backgroundColor: "#616161"}} className="s-box" id="iconSpacing">
              <i className="icon bi bi-linkedin"></i>
              <div className="name">LinkedIn</div>
            </motion.div>
          </motion.a>

          <motion.a whileHover={{textShadow: "0px 0px 5px rgb(255,255,255)", boxShadow: "0px 0px 10px rgb(255,255,255)"}} href="https://gitlab.com/waathiq.juma" target="_blank" role="button">
            <motion.div whileHover={{backgroundColor: "#616161"}} className="s-box" id="iconSpacing">
              <i className="icon bi bi-git"></i>
              <div className="name">Gitlab</div>
            </motion.div>
          </motion.a>

        </section>
        <div className="text-center text-white">
             WAATHIQ JUMA ©2022
        </div>
      </div>
    </div>
    
  );
};

export default Footer;
