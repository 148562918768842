import React from "react";
import {Link} from 'react-scroll';
import NavigationbarPageContent from "../pagecontent/NavigationbarPageContent";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/navigationbar.css';

const NavigationBar = () => {

  return (
      
    <nav className="navbar navbar-expand-sm navbar-dark">
      <button
        className="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNavDropdown"
        aria-controls="navbarNavDropdown"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div
        className="collapse navbar-collapse justify-content-center"
        id="navbarNavDropdown"
      >
        <ul className="navbar-nav">
          <li className="nav-item active">
            <Link activeClass="active" to="home" spy={true} smooth={true} className="nav-link">
              {NavigationbarPageContent.navItemTextOne}
            </Link>
          </li>

          <li className="nav-item active">
            <Link to="about" spy={true} smooth={true} className="nav-link">
              {NavigationbarPageContent.navItemTextTwo}
            </Link>
          </li>

          <li className="nav-item active">
            <Link to="portfolio" spy={true} smooth={true} className="nav-link">
              {NavigationbarPageContent.navItemTextThree}
            </Link>
          </li>

          <li className="nav-item active">
            <Link to="contact" spy={true} smooth={true} className="nav-link">
              {NavigationbarPageContent.navItemTextFour}
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default NavigationBar;
