import React from "react";
import ContactForm from "../components/ContactForm";
import ContactPageContent from "../pagecontent/ContactPageContent";
import "../css/contactPage.css";


class Contact extends React.Component {
  render() {
    return (
      <div id="contact">
      <h1 className="headingLevelTwoPosition">
        {ContactPageContent.headingText}
      </h1>
      <p className="paragraphFont">{ContactPageContent.paragraphText}</p>
      <ContactForm />
    </div>
    );
  }
}

export default Contact;