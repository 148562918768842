import React from "react";
import NavigationBar from "../components/Navigationbar";
import { BrowserRouter as Router} from "react-router-dom";
import Home from "../pages/Home";
import About from "../pages/About";
import Portfolio from "../pages/Portfolio";
import Contact from "../pages/Contact";
import Footer from "../components/Footer"

class MainPage extends React.Component {
  render() {
    return (
      <div className="main-content">
        <Router>
          <NavigationBar />
          <Home />
          <About />
          <Portfolio />
          <Contact />
          <Footer/>
        </Router>
      </div>
    );
  }
}

export default MainPage;
