import React, { useState } from 'react';
import '../css/contactForm.css';

const ContactForm = () => {

const [enteredName, setEnteredName] = useState('');
const [enteredNameError, setEnteredNameError] = useState(false);
const [nameErrorMessage, setNameErrorMessage] = useState("");

const [enteredEmail, setEnteredEmail] = useState('');
const [enteredEmailError, setEnteredEmailError] = useState(false);
const [emailErrorMessage, setEmailErrorMessage] = useState("");

const [enteredMessageBox, setEnteredMessageBox] = useState('');
const [enteredMessageBoxError, setEnteredMessageBoxError] = useState(false);
const [messageBoxErrorMessage, setMessageBoxErrorMessage] = useState("");


const nameHandler = (enteredNameValue) => {
    if (!enteredNameValue.target.value) {
        setEnteredNameError(true);
        setNameErrorMessage("Please enter your name.");
    } else if(enteredNameValue.length < 45 || (!/^[a-zA-Z\s]+$/.test(enteredNameValue.target.value))) {
        setEnteredNameError(true);
        setNameErrorMessage("Your name has an invalid character.");        
    } else {
        setEnteredNameError(false);
        setEnteredName(enteredNameValue.target.value);
    }
}

const emailChangeHandler = (email)=>{
  if(!email.target.value){
    setEnteredEmailError(true);
    setEmailErrorMessage("Please enter in your email.");
  } else if(email.length < 253 || (!/^[a-zA-Z0-9.!#$%^&*()-=_+]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email.target.value))) {
    setEnteredEmailError(true);
    setEmailErrorMessage("Your email address is incomplete.");  
  }else {
    setEnteredEmailError(false);
    setEnteredEmail(email.target.value);
  } 
}

const messageBoxHandler = (message) => {
  if (!message.target.value) {
    setEnteredMessageBoxError(true);
    setMessageBoxErrorMessage("Please do not leave the message box empty.")
  } else {
    setEnteredMessageBoxError(false);
    setEnteredMessageBox(message.target.value);
  }
}

const submitHandler = () => {
    if(enteredName === ""){
        setEnteredNameError(true);
        setNameErrorMessage("Please enter in your name.");
    }

    if (enteredEmail === "") {
      setEnteredEmailError(true);
      setEmailErrorMessage("Please enter in your email.");
    } 

    if (enteredMessageBox === "") {
      setEnteredMessageBoxError(true);
      setMessageBoxErrorMessage("Please enter in a message.")
    }
}



  return (
    <form action="https://formsubmit.co/waathiq.juma@gmail.com" method="POST">
    <div className="contactFormContainer">
      <div className="mb-3">
        <input
          required
          type="text"
          name="name"
          className={enteredNameError ? "form-control errorBox" :"form-control"}
          id="exampleFormControlInput1"
          placeholder="Name:"
          maxLength="253"
          onChange={nameHandler}
        />

        {enteredNameError && <p className="formErrorMessages">{nameErrorMessage}</p>}
        
        <input
          required
          type="email"
          name="email"
          className={enteredEmailError ? "form-control errorBox" : "form-control"}
          id="exampleFormControlInput1"
          placeholder="Email:"
          maxLength="253"
          onChange={emailChangeHandler}
        />
        {enteredEmailError && <p className="formErrorMessages">{emailErrorMessage}</p>}
      </div>

      <div className="mb-3">
        <textarea
          required
          className={enteredMessageBoxError ? "form-control errorBox" : "form-control"}
          id="exampleFormControlTextarea1"
          rows="3"
          placeholder='Please enter your message...'
          onChange={messageBoxHandler}
        ></textarea>
        {enteredMessageBoxError && <p className="formErrorMessages">{messageBoxErrorMessage}</p>}
      </div>

      <div className="buttonPosition">
        <button type="submit" className="btn btn-primary" id="contactBtn">Submit</button>
      </div>
    </div>
    </form>
  );
};

export default ContactForm;