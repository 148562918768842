import Teamwork from '../images/teamwork.jpg';
import Ambitious from '../images/ambitious.jpg';
import Learning from '../images/learning.jpg';
import WorkEthic from '../images/workethic.jpg';

const AboutPageContent = {
    headingText: "ABOUT",
    icontTextOneHeading:"Fast",
    icontTextOnePar:"Fast load times and lag-free interaction are my highest priority.",
    icontTextTwoHeading:"Responsive",
    icontTextTwoPar:"Creating layouts that will work on any device, big or small.",
    icontTextThreeHeading:"Intuitive",
    icontTextThreePar:"Strong preference for easy-to-use, intuitive UX/UI.",
    icontTextFourHeading:"Dynamic",
    icontTextFourPar:"Websites don't have to be static; I love making pages come to life!",
    imageHeadingText:"Who's this guy?",
    imageTextPar:"Hi, I’m a lead software engineer for U.S. Bank. My journey at the bank has brought me to enhance my skill set and focus on test automation (Selenium, TestCafe, & Rest Assured). In addition, I have developed a great interest in Front-End Development to create UI effects, animations, and intuitive and dynamic user experiences. Furthermore, I look forward to enhancing my skill set to become a cross-functional developer to inspire others who also want to pursue the same career path as mine. Thus, I hope to build on my current assets to create an established background of qualities.",
    listHeadingText:"Skills & Tools",
    listTextOne:"JAVA",
    listTextTwo:"REACT",
    listTextThree:"JENKINS",
    listTextFour:"HTML",
    listTextFive:"CSS",
    listTextSix:"DEDICATION",
    listTextSeven:"SELENIUM",
    listTextEight:"",
    listTextNine:"GRAPH QL",
    listTextTen:"TESTCAFE",
    listTextEleven:"GITLAB",
    listTextTwelve:"JIRA",
    teamworkImage:Teamwork,
    ambitousImage:Ambitious,
    learningImage:Learning,
    workethicImage:WorkEthic,
    charCardContent:"To become an effective team leader, one must learn how to take pride in creating a harmonious atmosphere that requires the ability to collaborate and work effectively as a team.",
    charCardOneContent:"My ambition in life is to one day become a successful full-stack developer. While I have practical experience, I am looking for the next challenge to improve and develop my skill set.",
    charCardTwoContent:"As an avid learner, I have become humble and love actively seeking to shape a better version of myself. One that I can dedicate myself to thriving in any environment. When a new learning opportunity is presented to me, I am right there to incorporate it into my work and teach others.",
    charCardThreeContent:"I take pride in my work to deliver tasks at hand on time, whether prioritizing my tasks, aligning my functions with the team's goals, or balancing work/life to avoid burnout. Therefore, I will always be there to deliver quality, on-time work.",
}

export default AboutPageContent;