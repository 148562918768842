import React, {useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import PortfolioPageContent from "../pagecontent/PortfolioPageContent";
import PortfolioCards from '../components/PortfolioCards';
import "../css/portfolioPage.css";
import "../css/animations.css";

const PortfolioHeader = () => {
  return (
    <div>
      <h1 className="headingFontStyle">
        {PortfolioPageContent.headingText}
      </h1>
    </div>
  );
};

const FadeInWhenVisible = () => {
  const controls = useAnimation();
  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  const animatedVariants = {
    hidden: { scale: 0 },
    visible: {
      scale: 1,
      transition: {
        duration: 1.0,
      },
    },
  };

  return (
    <motion.div
      ref={ref}
      className="PotfolioFadeIn"
      initial="hidden"
      animate={controls}
      variants={animatedVariants}
    >
      <PortfolioHeader/>
      <PortfolioCards/>   
    </motion.div>
  );
};

class Portfolio extends React.Component {
  render() {
    return (
    <section id="portfolio">
        <FadeInWhenVisible/>
    </section>);
  }
}

export default Portfolio;
