import React, { useState } from "react";
import Carousel from "../components/Carousel";
import BackdropModalPageContent from "../pagecontent/BackdropModalPageContent";
import "../css/backdropModal.css";
import "../css/portfolioCards.css";

const BackdropModal = (props) => {
  const [showButton, setShowButton] = useState(false);

  const myindexValue = props.modalNames.indexValue;

  const onClickHandler = () => {
    if (myindexValue === 1) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  };

  const HiddenButton = () => {
    return (
      <div>
        <a
          href={BackdropModalPageContent.hiddenButtonOne}
          target="_blank"
          rel="noopener noreferrer"
          className="btn btn-primary"
          id="primaryButton"
        >
          View Source Code
        </a>
      </div>
    );
  };

  return (
    <div>
      <div className="buttonPos">
        <button
          type="button"
          className="btn btn-primary buttonDisplay"
          data-bs-toggle="modal"
          data-bs-target={"#staticBackdrop" + props.modalNames.indexValue}
          id="primaryButton"
        >
          Learn More
        </button>

        <div
          className="modal fade"
          id={"staticBackdrop" + props.modalNames.indexValue}
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-labelledby={"staticBackdropLabel" + props.modalNames.indexValue}
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg modal-dialog-centered myModal">
            <div className="modal-content">
              <div className="modal-header">
                <h5
                  className="modal-title"
                  id={"staticBackdropLabel" + props.modalNames.indexValue}
                >
                  {props.modalNames.headingFive}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div>
                <Carousel theImgs={props.modalNames.carImgs} />
              </div>
              <div className="modal-body">{props.modalNames.bodyParagraph}</div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  id="secondaryButton"
                >
                  {props.modalNames.secondButton}
                </button>
                <a
                  href={props.modalNames.buttonLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn-primary"
                  id="primaryButton"
                  onClick={onClickHandler}
                >
                  {props.modalNames.thirdButton}
                </a>
                <div>{showButton ? <HiddenButton /> : null}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BackdropModal;