import React, { Component } from "react";
import ParticlesBg from "particles-bg";
import "../css/particlesBackground.css";

class ReactParticles extends Component {
  
    render() {
    return (
      <ParticlesBg
        color="#f6f6f6"
        className="background"
        type="cobweb"
        num={150}
        bg={true}
        
      />
    );
  }
}

export default ReactParticles;
