import React from "react";
import ReactParticles from "../components/ReactParticles";
import HomePageContent from "../pagecontent/HomePageContent";
import PrimaryButton from "../components/PrimaryButton";
import "../css/homePage.css";

const HomePageContentIntroduction = () => {
  return (
    
      <div className="flex" id="indexPos">
        <span id="textColor">
          {HomePageContent.helloText}
          <span id="changeColorText">{HomePageContent.nameText}</span>
        </span>
        <span id="textColor">{HomePageContent.webDeveloperText}</span>
        <PrimaryButton />
      </div>
    
  );
};

class Home extends React.Component {
  render() {
    return (
      <section id="home">
         <HomePageContentIntroduction/>
        <ReactParticles/>
      </section>
    );
  }
}

export default Home;
