import React from "react";
import { Link } from "react-scroll";
import {motion} from "framer-motion"
import "../css/primaryButton.css";


const buttonText = "View my work";

const PrimaryButton = () => {
  return (
    <motion.button whileHover={{scale: 1.1, textShadow: "0px 0px 2px rgb(255,255,255)", boxShadow: "0px 0px 10px rgb(255,255,255)"}} type="button" className="btn btn-outline-primary" id="button">
      <Link to="portfolio" spy={true} smooth={true}>
      {buttonText}      
      </Link>
      <i className="bi bi-chevron-double-right"></i>
    </motion.button>
  );
};

export default PrimaryButton;
