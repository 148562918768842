import React from "react";
import "../css/carousel.css";

const IsFirst = (prop) => {
  if (prop === 1) {
    return " active";
  } else {
    return "";
  }
};

const SliderCarousel = (props) => {
  return (
    <div className={"carousel-item" + IsFirst(props.theID)}>
      <img src={props.image} className="d-block w-100" alt="..." />
      <div className="carousel-caption d-none d-md-block"></div>
    </div>
  );
};

const Carousel = (props) => {
  return (
    <div id="carouselImg" className="carousel slide" data-bs-ride="carousel">
      <div className="carousel-indicators">
        {props.theImgs.map((myimages) => (
          <button
            type="button"
            key={myimages.carID}
            data-bs-target="#carouselImg"
            data-bs-slide-to={myimages.carID - 1}
            className={IsFirst(myimages.carID)}
            aria-current="true"
            aria-label="Slide 1"
          ></button>
        ))}
      </div>
      <div className="carousel-inner">
        {props.theImgs.map((myimages) => (
          <SliderCarousel
            key={myimages.carID}
            theID={myimages.carID}
            image={myimages.img}
          />
        ))}
      </div>
    </div>
  );
};

export default Carousel;
